
import Patient from '@/models/Patient'
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'
import SimpleQuestion from '@/components/SimpleQuestion.vue'
import SimpleNumber from '@/components/SimpleNumber.vue'
import Select from '@/components/Select.vue'
import RowScaleButton from '@/components/RowScaleButton.vue'
import RadioN from '@/components/RadioN.vue'
import Swal from 'sweetalert2'

@Options({
  components: {
    SimpleQuestion,
    SimpleNumber,
    RadioN,
    Select,
    RowScaleButton
  },
  methods: mapActions(['updateTreatmentResult', 'downloadTreatmentResult']),
  computed: mapGetters(['patient', 'treatmentResult'])
})
export default class TreatmentResult extends Vue {
  patient!: Patient
  treatmentResult!: Patient['treatmentResult']

  // Actions
  updateTreatmentResult!: (payload: {
    id: number
    treatmentResult: Patient['treatmentResult']
  }) => Promise<void>

  downloadTreatmentResult!: (alias: string) => Promise<void>

  async created (): Promise<void> {
    const alias = String(this.$route.params.alias)
    try {
      await this.downloadTreatmentResult(alias)
    } catch (err: any) {
      const message = err.response.data.message
      if (message === 'Patient not found.') {
        await Swal.fire({
          icon: 'info',
          title: this.$t('TreatmentResult.DownloadFailedTitle'),
          confirmButtonText: this.$t(
            'TreatmentResult.DownloadFailedConfirmButton'
          )
        })
        this.$router.push({ name: this.$route.meta.next })
      }
    }
  }

  async goToAdhdSeverityScale (): Promise<void> {
    await this.updateTreatmentResult({
      id: this.patient.id,
      treatmentResult: this.treatmentResult
    })

    this.$router.push({
      name: 'AdHdSeverityScaleTreatment',
      params: { alias: this.$route.params.alias }
    })
  }

  async submit (): Promise<void> {
    try {
      await this.updateTreatmentResult({
        id: this.patient.id,
        treatmentResult: this.treatmentResult
      })

      await Swal.fire({
        title: this.$t('TreatmentResult.UpdateTitle'),
        confirmButtonText: this.$t('TreatmentResult.UpdateConfirmButton')
      })

      this.$router.push({ name: this.$route.meta.next })
    } catch {
      this.$router.push({ name: 'Logout' })
    }
  }
}
