
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    value: Number,
    min: Number,
    max: Number,
    text: String,
    subQuestionLvl: Number
  }
})
export default class SimpleQuestion extends Vue {
  value!: number
  min = 0
  max = 10
  mutableValue!: number
  subQuestionLvl!: number

  created (): void {
    this.mutableValue = this.value
  }

  emit (): void {
    this.$emit('changed', this.mutableValue)
    this.$emit('change')
  }
}
