import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200" }
const _hoisted_2 = { class: "grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 flex h-full" }
const _hoisted_3 = { class: "flex flex-col items-center py-4 gap-2" }
const _hoisted_4 = ["min", "max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass([{
        'sm:pl-8': _ctx.subQuestionLvl === 1,
        'sm:pl-10': _ctx.subQuestionLvl === 2,
        'sm:pl-12': _ctx.subQuestionLvl === 3,
        'sm:pl-14': _ctx.subQuestionLvl === 4,
        'bg-gray-50': _ctx.subQuestionLvl === 1,
        'bg-gray-100': _ctx.subQuestionLvl === 2,
        'bg-gray-200': _ctx.subQuestionLvl === 3,
        'bg-gray-300': _ctx.subQuestionLvl === 4
      }, "block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"])
    }, _toDisplayString(_ctx.text), 3),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["col-span-3 flex flex-col justify-center bg-gray-200", {
          'bg-gray-300': _ctx.subQuestionLvl === 1,
          'bg-gray-400': _ctx.subQuestionLvl === 2,
          'bg-gray-500': _ctx.subQuestionLvl === 3,
          'bg-gray-600': _ctx.subQuestionLvl === 4
        }])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mutableValue) = $event)),
            type: "number",
            class: "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md w-max",
            min: _ctx.min,
            max: _ctx.max,
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit()))
          }, null, 40, _hoisted_4), [
            [_vModelText, _ctx.mutableValue]
          ])
        ])
      ], 2)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}