import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200" }
const _hoisted_2 = { class: "grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 flex h-full" }
const _hoisted_3 = { class: "flex flex-row justify-center items-center py-4 gap-2" }
const _hoisted_4 = {
  key: 0,
  class: "mr-3 relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 bg-gray-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass([{
        'sm:pl-8': _ctx.subQuestionLvl === 1,
        'sm:pl-10': _ctx.subQuestionLvl === 2,
        'sm:pl-12': _ctx.subQuestionLvl === 3,
        'sm:pl-14': _ctx.subQuestionLvl === 4,
        'bg-gray-50': _ctx.subQuestionLvl === 1,
        'bg-gray-100': _ctx.subQuestionLvl === 2,
        'bg-gray-200': _ctx.subQuestionLvl === 3,
        'bg-gray-300': _ctx.subQuestionLvl === 4
      }, "block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"])
    }, _toDisplayString(_ctx.text), 3),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["col-span-3 flex flex-col justify-center bg-gray-200", {
          'bg-gray-300': _ctx.subQuestionLvl === 1,
          'bg-gray-400': _ctx.subQuestionLvl === 2,
          'bg-gray-500': _ctx.subQuestionLvl === 3,
          'bg-gray-600': _ctx.subQuestionLvl === 4
        }])
      }, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.modelValue)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.modelValue), 1))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goToScale && _ctx.goToScale(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.$t('ScaleButton.Scale')), 1)
        ])
      ], 2)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}